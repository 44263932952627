import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./ProductSpecification.css";

function ProductSpecification() {
  const [spec, setSpec] = useState({
    species: "",
    genetics: "",
    smell: "",
    flavor: "",
    description: "",
    sativa: 0,
    indica: 0,
    thc: 0,
    cbd: 0,
  });
  const [loading, setLoading] = useState(false);

  const { product } = useParams();

  useEffect(() => {
    setLoading(true);

    fetch(`https://flor-charrua-backend.vercel.app/products/${product}`, {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJson) => {
        setSpec(resJson);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <p>Loading... </p>
  ) : (
    <>
      <Header headerMiddle={product} goBack={true} />
      <main className="product-specification">
        <img
          src={`/Imgs/${product.replace(/\s+/g, "").toLowerCase()}.jpg`}
          alt=""
        />
        <p className="important">
          Sativa: {spec.sativa}% Indica: {spec.indica}%
        </p>{" "}
        <p className="important">
          THC: {spec.thc}% CBD: {spec.cbd}%
        </p>
        <p>Genetica: {spec.genetics}</p>
        <p>Aroma: {spec.smell}</p>
        <p>Sabor: {spec.flavor}</p>
        <p>{spec.description}</p>
      </main>
    </>
  );
}

export default ProductSpecification;
