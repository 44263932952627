import { useState } from "react";
import Button from "../../General/Button";
import Input from "../../General/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function CartComponents({ species, amount, price, setPreorder, preorder }) {
  const [qInput, setQInput] = useState(amount);
  let newName = species.replace(/\s+/g, "").toLowerCase();

  const submitChange = () => {
    setPreorder({ ...preorder, [newName]: parseInt(qInput) });
  };

  const manageInput = (e) => {
    let operation = e.target.innerHTML;
    if (operation == "+") {
      setQInput((i) => i + 5);
      setPreorder({ ...preorder, [newName]: parseInt(qInput) + 5 });
    } else if (operation == "-") {
      setQInput((i) => i - 5);
      setPreorder({ ...preorder, [newName]: parseInt(qInput) - 5 });
    }
  };

  return (
    preorder[newName] > 0 && (
      <div className="summary-product">
        <div className="counter">
          <span onClick={(e) => manageInput(e)}>+</span>
          <p>{qInput}</p>
          <span onClick={(e) => manageInput(e)}>-</span>
        </div>
        <img className="image" src={`/Imgs/${newName}.jpg`} />
        <div className="specs">
          <FontAwesomeIcon
            icon={faXmark}
            className="close"
            onClick={() => setPreorder({ ...preorder, [newName]: 0 })}
          />
          <p className="price">${price * qInput}</p>
          <p className="name">{species}</p>
        </div>
      </div>
    )
  );
}

export default CartComponents;
