import { useEffect, useState } from "react";
import Button from "../../General/Button";
import Header from "../../Header/Header";
import "./Harvest.css";
import HarvestTable from "./HarvestTable";
import { Loading } from "../../Loading/loading";

function Harvest() {
  const [loading, setLoading] = useState(false);
  const [harvests, setHarvests] = useState([]);
  const [selectedHarvest, setSelectedHarvest] = useState([]);
  const [dates, setDates] = useState([]);
  const [search, setSearch] = useState({
    id: 0,
    value: "",
  });

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/harvests", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        console.log(resJ);
        setHarvests(resJ.res);
        setDates(resJ.harvest_dates);
      })
      .catch((err) => console.log("error", err))
      .finally(() => setLoading(false));
  }, []);

  const manageSelect = (e) => {
    setSearch({
      value: e.target.value,
    });
  };

  const getTheHarvest = () => {
    if (search.value === "") {
      return;
    }
    setLoading(true);
    fetch(
      `https://flor-charrua-backend.vercel.app/admin/harvests/${search.value}`,
      {
        method: "GET",
        headers: {
          auth_token: localStorage.getItem("auth_token"),
        },
      }
    )
      .then((res) => res.json())
      .then((resJ) => {
        setSelectedHarvest(resJ);
      })
      .catch((err) => console.log("error", err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Header goBack={true} headerMiddle="Cosechas" />
      {loading ? (
        <Loading />
      ) : (
        <main className="harvest-main">
          <div>
            <select value={search.value} onChange={(e) => manageSelect(e)}>
              <option value="" disabled>
                Elija Cosecha
              </option>
              {dates.map((d, k) => {
                let thisDate = d.split(" ");
                thisDate = thisDate[2] + " " + thisDate[1] + ", " + thisDate[3];
                let thisVlue =
                  new Date(thisDate).getFullYear() +
                  "-" +
                  (new Date(thisDate).getMonth() + 1) +
                  "-" +
                  new Date(thisDate).getDate();
                return (
                  <option key={k} value={thisVlue}>
                    {thisDate}
                  </option>
                );
              })}
            </select>
            <Button text="Ver" onClick={() => getTheHarvest()} />
          </div>
          {selectedHarvest.length < 1
            ? null
            : selectedHarvest.map((harv, k) => {
                let thisName = harvests.filter(
                  (h) => h.product_id === harv.product_id
                );
                thisName = thisName.filter(
                  (d) => d.harvest_date === harv.harvest_date
                );

                return (
                  <HarvestTable
                    key={k}
                    selectedHarvest={harv}
                    name={thisName[0].species}
                  />
                );
              })}
        </main>
      )}
    </>
  );
}

export default Harvest;
