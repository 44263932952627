function HarvestTable({ selectedHarvest, name }) {
  return (
    <section>
      <h3>{name}</h3>
      <p>
        Numero de plantas: <span>{selectedHarvest.number_of_plants}</span>
      </p>
      <p>
        Fecha de esquejado:{" "}
        <span>
          {selectedHarvest.sketched_date
            ? selectedHarvest.sketched_date
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Primer transplante:{" "}
        <span>
          {selectedHarvest.first_transplantation
            ? selectedHarvest.first_transplantation
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Segundo Transplante:{" "}
        <span>
          {selectedHarvest.second_transplantation
            ? selectedHarvest.second_transplantation
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Tercer transplante:{" "}
        <span>
          {selectedHarvest.third_transplantation
            ? selectedHarvest.third_transplantation
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Fecha de floracion:{" "}
        <span>
          {selectedHarvest.bloom_date
            ? selectedHarvest.bloom_date
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Fecha de cosechado:{" "}
        <span>
          {selectedHarvest.harvest_date
            ? selectedHarvest.harvest_date
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : null}
        </span>
      </p>
      <p>
        Stock total: <span>{selectedHarvest.total_stock}g</span>
      </p>
      <p>
        Stock actual: <span>{selectedHarvest.stock}g</span>
      </p>
      <p>
        Media de produccion:{" "}
        <span>{selectedHarvest.production_average}g/planta</span>
      </p>
      <p>
        Peformance individual:{" "}
        <span>{selectedHarvest.plant_performance}g/watt</span>
      </p>
      <p>
        Perdidas: <span>{selectedHarvest.waste}g</span>
      </p>
      <p>
        Observaciones: <span>{selectedHarvest.observations}</span>
      </p>
    </section>
  );
}

export default HarvestTable;
