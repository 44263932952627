import "./Carta.css";
import Header from "../Header/Header";
import Product from "./Product";
import { useEffect, useState } from "react";
import { Loading } from "../Loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";

function Carta({ setPreorder, preorder }) {
  const [products, setProducts] = useState([
    {
      species: "",
      stock: 0,
      sativa: 0,
      indica: 0,
      tch: 0,
      cbd: 0,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [added, setAdded] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/products", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((rjson) => {
        setProducts(rjson);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <Header headerMiddle={"Carta"} />
      <main className="carta">
        {added && (
          <p className="agenda">
            Agendá tu pedido!{" "}
            <FontAwesomeIcon className="icon" icon={faArrowTurnUp} />
          </p>
        )}
        {products.map((p, k) => {
          return (
            <Product
              key={k}
              productMainImg={`/Imgs/${p.species
                .replace(/\s+/g, "")
                .toLowerCase()}.jpg`}
              product={p}
              outOfStock={p.stock <= 40 ? true : false}
              setPreorder={setPreorder}
              preorder={preorder}
              added={added}
              setAdded={setAdded}
            />
          );
        })}
      </main>
      {/* <Footer /> */}
    </>
  );
}

export default Carta;
