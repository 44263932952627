import "./Menu.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";

function Menu({ items, goBack, admin }) {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (admin) {
  //     items = items.filter((f) => f.admin);
  //   }
  //   console.log(items);
  // }, []);

  return goBack ? (
    <div className="go-back">
      <FontAwesomeIcon
        className="icon"
        icon={faArrowLeft}
        src="/Imgs/left-arrow.png"
        alt="Go Back Icon"
        onClick={() => navigate(-1)}
      />
    </div>
  ) : (
    <div className="menu-items">
      <FontAwesomeIcon
        className="icon"
        icon={faBars}
        alt="menu Icon"
        aria-haspopup="menu"
        aria-expanded={dropdown ? "true" : "false"}
        onClick={() => setDropdown((prev) => !prev)}
      />
      <Dropdown items={items} dropdown={dropdown} />
    </div>
  );
}

export default Menu;
