import "./Manicure.css";
import Input from "../../General/Input";
import Button from "../../General/Button";
import { useEffect, useState } from "react";
import { Loading } from "../../Loading/loading";

function Manicure({ setModal }) {
  const [socio, setSocio] = useState(false);
  const [socioName, setSocioName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("Agregar");
  const [data, setData] = useState({
    harvest_type: "",
    socio_name: "",
    nosocio_name: "",
    start_hour: "",
    end_hour: "",
    date: "",
  });

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/getsocios", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        console.log(navigator.userAgent);
        setSocioName(() => {
          return resJ.filter((r) => r.id === 41 || r.id === 26 || r.id === 132);
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const manageData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const uploadData = (e) => {
    e.preventDefault();
    if (
      (!data.socio_name && !data.nosocio_name) ||
      !data.harvest_type ||
      !data.start_hour ||
      !data.end_hour ||
      !data.date
    ) {
      setMsg("Faltan datos");
    } else {
      setMsg("Agregar");
      setLoading(true);
      fetch("https://flor-charrua-backend.vercel.app/admin/uploadharvest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          auth_token: localStorage.getItem("auth_token"),
        },
        body: JSON.stringify(data),
      })
        .catch((err) => console.log(err))
        .finally(() => {
          setData({
            ...data,
            socio_name: "",
            nosocio_name: "",
            start_hour: "",
            end_hour: "",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div className="manicure-div">
      {loading ? (
        <Loading />
      ) : (
        <form className="manicure-form" onSubmit={(e) => uploadData(e)}>
          <p className="close" onClick={() => setModal(false)}>
            X
          </p>
          <select
            name="harvest_type"
            value={data.harvest_type}
            onChange={(e) => manageData(e)}
          >
            <option value="" disabled>
              Elegir manicura
            </option>
            <option value="thick_harvest">Manicura gruesa</option>
            <option value="thin_harvest">Manicura fina</option>
          </select>
          <div>
            <label>Socio</label>
            <Input
              name="socio"
              type="checkbox"
              onChange={() => {
                setSocio(!socio);
                setData({
                  ...data,
                  socio_name: "",
                  nosocio_name: "",
                  start_hour: "",
                  end_hour: "",
                  date: "",
                });
              }}
            />
          </div>
          {socio ? (
            <select
              name="socio_name"
              value={data.socio_name}
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            >
              <option value="" disabled>
                Socio
              </option>
              {socioName.map((s, k) => {
                return (
                  <option key={k} value={s.name}>
                    {s.name}
                  </option>
                );
              })}
            </select>
          ) : (
            <div>
              <label>Nombre</label>
              <Input
                name="nosocio_name"
                placeholder="Nombre"
                value={data.nosocio_name}
                onChange={(e) => manageData(e)}
              />
            </div>
          )}
          <div>
            <label>Hora de comienzo</label>
            <Input
              name="start_hour"
              placeholder="hh:mm"
              value={data.start_hour}
              onChange={(e) => manageData(e)}
            />
          </div>
          <div>
            <label>Hora de fin</label>
            <Input
              placeholder="hh:mm"
              name="end_hour"
              value={data.end_hour}
              onChange={(e) => manageData(e)}
            />
          </div>
          <div>
            <label>Fecha de manicura</label>
            <Input
              placeholder="fecha"
              name="date"
              value={data.date}
              onChange={(e) => manageData(e)}
              type="date"
            />
          </div>
          {msg !== "Faltan datos" ? null : <p className="err">*{msg}</p>}
          <Button text={msg} type="submit" />
        </form>
      )}
    </div>
  );
}

export default Manicure;
