import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../General/Button";
import Input from "../General/Input";
import "./Login.css";

function Login() {
  const [user, setUser] = useState({
    mail: "",
    password: "",
  });
  const [valid, setValid] = useState("");
  const navigate = useNavigate();

  const manageUser = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const login = () => {
    fetch("https://flor-charrua-backend.vercel.app/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        mail: user.mail,
        password: user.password,
      }),
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.msg === "Invalid Mail") {
          setValid("Mail incorrecto");
          return;
        }
        if (resJson.msg === "Invalid Mail or Password") {
          setValid("Mail o contraseña incorrectos");
          return;
        }
        localStorage.setItem("auth_token", resJson.auth_token);
        if (resJson.pass === "old") {
          navigate("/");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally();
  };

  return (
    <div className="all-div">
      <main className="login-main">
        <h2>INICIO DE SESIÓN</h2>
        <img src="/logo.png" className="logo" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <Input
            name="mail"
            type="email"
            value={user.mail}
            onChange={(e) => manageUser(e)}
            placeholder="Correo electrónico"
          />
          <Input
            name="password"
            value={user.password}
            type="password"
            onChange={(e) => manageUser(e)}
            placeholder="Constraseña"
          />
          {valid ? <p>{valid}</p> : <p>{valid}</p>}
          <Button type="submit" text="ACCEDER" />
        </form>
      </main>
    </div>
  );
}

export default Login;
