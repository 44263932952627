import React, { useEffect, useState } from "react";
import "./Carousel.css";

export const CarouselItem = ({ children, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

export const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = React.Children.map(children, (child, k) => {
    return React.cloneElement(child, { width: "calc(100vw - 2rem)" });
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeIndex >= slides.length - 1) {
        updateIndex(0);
      } else {
        updateIndex(activeIndex + 1);
      }
    }, 7000);
    return () => clearInterval(interval);
  });

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = React.Children.count(children) - 1;
    }

    setActiveIndex(newIndex);
  };

  return (
    <div className="carousel">
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, k) => {
          return React.cloneElement(child, { width: "calc(100% - 2em)" });
        })}
      </div>
      {slides.length < 2 ? undefined : (
        <div className="indicators">
          {/* <button
            onClick={() => {
              if (activeIndex === 0) {
                updateIndex(slides.length - 1);
              } else {
                updateIndex(activeIndex - 1);
              }
            }}
          >
            Prev
          </button> */}
          {React.Children.map(children, (child, i) => {
            return (
              <button
                className={`round ${i === activeIndex ? "active" : ""}`}
                onClick={() => {
                  updateIndex(i);
                }}
              />
            );
          })}
          {/* <button
            onClick={() => {
              console.log(React.Children);
              if (activeIndex >= slides.length - 1) {
                updateIndex(0);
              } else {
                updateIndex(activeIndex + 1);
              }
            }}
          >
            Next
          </button> */}
        </div>
      )}
    </div>
  );
};

const automatic = () => {
  let time = new Date();
};
