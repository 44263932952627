import "./loading.css";

export function Loading420() {
  return (
    <div className="loading">
      <img src="/Imgs/joint.gif" alt="Loading joint" />;
    </div>
  );
}

export function Loading() {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}
