import Header from "../../Header/Header";
import "./Todos.css";
import { todos } from "../../../lib/data";
import { useEffect, useState } from "react";
import { Loading } from "../../Loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function Todos() {
  const [open, setOpen] = useState("floracion");
  const [form, setForm] = useState({
    socio_id: 0,
    date: new Date(),
    workdone: {
      floracion: [],
      preparto: [],
      crecimiento: [],
      antes_de_salir: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/getUser", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((rjson) => {
        setForm({ ...form, socio_id: rjson.id });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const manageForm = (e) => {
    const target = e.target;
    // console.log(form.workdone[target.name]);
    if (target.checked) {
      const newWork = [...form.workdone[target.name], target.value];
      setForm({
        ...form,
        workdone: { ...form.workdone, [target.name]: newWork },
      });
    } else {
      const newWork = form.workdone[target.name].filter(
        (i) => i !== target.value
      );
      setForm({
        ...form,
        workdone: { ...form.workdone, [target.name]: newWork },
      });
    }
    console.log(form);
  };

  const sendForm = (e) => {
    setLoadingRequest(true);
    e.preventDefault();
    fetch("https://flor-charrua-backend.vercel.app/admin/addCheckedTodos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        auth_token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify(form),
    })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setForm({
          socio_id: 0,
          date: new Date(),
          workdone: {
            floracion: [],
            preparto: [],
            crecimiento: [],
            antes_de_salir: [],
          },
        });
        setOpen("floracion");
        setLoadingRequest(false);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Header />
      <main className="todos">
        <h2>Tareas</h2>
        <nav className="horizontal-elector">
          <h3
            title="floracion"
            onClick={(e) => setOpen(e.target.title)}
            style={{
              borderBottom: open === "floracion" ? "4px solid green" : "",
            }}
          >
            Floracion
          </h3>
          <h3
            title="preparto"
            onClick={(e) => setOpen(e.target.title)}
            style={{
              borderBottom: open === "preparto" ? "4px solid green" : "",
            }}
          >
            Preparto
          </h3>
          <h3
            title="crecimiento"
            onClick={(e) => setOpen(e.target.title)}
            style={{
              borderBottom: open === "crecimiento" ? "4px solid green" : "",
            }}
          >
            Crecimiento
          </h3>
          <h3
            title="antes_de_salir"
            onClick={(e) => setOpen(e.target.title)}
            style={{
              borderBottom: open === "antes_de_salir" ? "4px solid green" : "",
            }}
          >
            Antes de salir
          </h3>
        </nav>
        <section className="checks">
          {todos[open].map((t, k) => {
            return (
              <label key={k}>
                <input
                  name={open}
                  value={t}
                  type="checkbox"
                  checked={
                    form.workdone[open].filter((i) => i === t).length > 0
                  }
                  onChange={(e) => manageForm(e)}
                />
                {t}
              </label>
            );
          })}
        </section>
        <button onClick={(e) => sendForm(e)}>
          {loadingRequest ? <FontAwesomeIcon icon={faSpinner} /> : "ENVIAR"}
        </button>
      </main>
    </>
  );
}

export default Todos;
