import "./News.css";
import { Carousel, CarouselItem } from "../Carousel/Carousel";
import Header from "../Header/Header";
import { newsData } from "../../lib/data";
import { Link } from "react-router-dom";

function News() {
  return (
    <>
      <Header headerMiddle="Noticias" />
      <main className="news">
        <Carousel>
          {newsData.map((n, k) => {
            return (
              <CarouselItem key={k}>
                <a href={n.link} target="_blank">
                  {k === 0 ? <h2>{n.new}</h2> : <h2>"{n.new}"</h2>}
                  <img src={n.src} alt="Noticia" />
                </a>
              </CarouselItem>
            );
          })}
        </Carousel>
      </main>
      {/* <Footer /> */}
    </>
  );
}

export default News;
