import "./Footer.css";
import data from "../../clubData.json";

function Footer() {
  return (
    <footer className="main-footer">
      <p>Calidad y buen sabor</p>
      <aside>
        <span>{data.mail}</span>
        <span>{data.phone}</span>
      </aside>
    </footer>
  );
}
export default Footer;
