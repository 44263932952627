import { useState } from "react";
import StockEditModal from "./StockEditModal";

function ProductStock({ id, species, price, available }) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <section className="stock-section" onClick={() => setModal(true)}>
        <h3>
          {species} #{id}
        </h3>
        <p>
          Precio: <span>{price}</span>
        </p>
        <p style={{ color: `${available ? "green" : "red"}` }}>
          Disponible: <span>{available ? "Si" : "No"}</span>
        </p>
      </section>
      {modal && (
        <StockEditModal
          id={id}
          species={species}
          price={price}
          available={available}
          setModal={setModal}
        />
      )}
    </>
  );
}

export default ProductStock;
