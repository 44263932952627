import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import Button from "../../General/Button";

function ExpenseTable({ thisDate, data, months }) {
  const [total, setTotal] = useState(0);
  const [verRec, setVerRec] = useState(false);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState([]);
  const [userID, setUserID] = useState("");
  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_ANON_KEY
  );

  useEffect(() => {
    if (data[0] != null) {
      for (let i = 0; i < data.length; i++) {
        setTotal((t) => {
          return t + parseInt(data[i].amount);
        });
      }
    }
  }, [data]);
  useEffect(() => {
    getUser();
  }, [userID]);

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUserID(user.id);
        setLoading(false);
      } else {
        setUserID("");
        setLoading(false);
      }
    } catch (e) {}
  };
  const getMedia = async (cat, date) => {
    const { data: info, err } = await supabase.storage
      .from("Bills")
      .list(userID + `/${cat}/${date}/`, {
        offset: 0,
        sortBy: {
          column: "name",
          order: "asc",
        },
      });
    if (info) {
      setMedia(info);
    } else {
      console.log("Error", err);
    }
  };

  return (
    <section className="expense-table">
      <h3>
        Fecha:
        <span> {thisDate}</span>
      </h3>
      <div>
        {data.map((d, k) => {
          return (
            <div key={k}>
              <h4>{d.name}</h4>
              <div>
                <p>
                  Fecha:{" "}
                  <span>
                    {d.date.split("-")[2].split("T")[0]}/{d.date.split("-")[1]}/
                    {d.date.split("-")[0]}
                  </span>
                </p>
                <p>
                  Gasto: <span>$ {d.amount}</span>
                </p>
                <p>
                  Comentaio: <span>{d.comment}</span>
                </p>
              </div>
              <Button
                text="Recibos"
                onClick={() => {
                  setVerRec(!verRec);
                  getMedia(d.name, thisDate.split(", ").join("-"));
                }}
              />
              {verRec
                ? media.map((m, k2) => {
                    return (
                      <a
                        key={k2}
                        href={`https://mfgcraomtlxihivkqmhl.supabase.co/storage/v1/object/public/Bills/${userID}/${
                          d.name
                        }/${thisDate.split(", ").join("-")}/${m.name}`}
                        target="_blank"
                      >
                        Ver Recibo
                      </a>
                    );
                  })
                : undefined}
            </div>
          );
        })}
        <p className="total">
          Total Mensual: <span>$ {total}</span>
        </p>
      </div>
    </section>
  );
}

export default ExpenseTable;
