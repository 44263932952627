import Header from "../Header/Header";
import data from "../../clubData.json";
import "./AboutUs.css";

function AboutUs() {
  return (
    <>
      <Header headerMiddle="Nosotros" />
      <main className="aboutus">
        <div className="us">
          <div className="inner-div">
            <h3>NOSOTROS</h3>
            <div className="line"></div>
          </div>
          <p>{data.aboutus[0]}</p>
        </div>
        {/* <div className="mision">
          <div className="inner-div">
            <div className="line"></div>
            <h3>NUESTRA MISIÓN</h3>
          </div>
          <p>{data.aboutus[1]}</p>
        </div> */}
        <img className="logo" src="/logo.png" />
      </main>
    </>
  );
}

export default AboutUs;
