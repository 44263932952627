import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import "./Orders.css";
import OrderTable from "./OrderTable";
import { Loading } from "../../Loading/loading";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [dates, setDates] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/seeorders", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        console.log(resJ);
        setDates((d) => {
          let allDates = [...new Set([...d, ...resJ.map((r) => r.date)])];
          let newDates = allDates.filter(
            (d) => new Date(d).getTime() > new Date().getTime() - 86400000
          );
          return newDates.sort((a, b) =>
            a
              .split("/")
              .reverse()
              .join()
              .localeCompare(b.split("/").reverse().join())
          );
        });
        setOrders(resJ);
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setDates((d) => {
          return (d = [...new Set(d)]);
        });
        setLoading(false);
      });
  }, []);

  const manageSelect = (e) => {
    setSelectedDate(e.target.value);
  };
  return (
    <>
      <Header goBack={true} headerMiddle="Ordenes" />
      {loading ? (
        <Loading />
      ) : (
        <main className="orders-main">
          <select onChange={(e) => manageSelect(e)} value={selectedDate}>
            <option value="" disabled>
              Elija Fecha
            </option>
            {dates.map((d, k) => {
              let thisDate = d.split("T")[0].split("-");
              thisDate = thisDate[2] + "/" + thisDate[1] + "/" + thisDate[0];
              return (
                <option key={k} value={thisDate}>
                  {thisDate}
                </option>
              );
            })}
          </select>
          {selectedDate ? (
            <OrderTable data={orders} date={selectedDate} />
          ) : null}
        </main>
      )}
    </>
  );
}

export default Orders;
