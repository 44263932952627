const menuItems = [
  {
    title: "Inicio",
    url: "/",
  },
  {
    title: "Catálogo",
    url: "/carta",
  },
  {
    title: "Nosotros",
    url: "/aboutus",
  },
  {
    title: "Noticias",
    url: "/news",
  },
  {
    title: "Administrar",
    url: "/admin",
    admin: true,
  },
];

export default menuItems;
