import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import "./AddBill.css";
import { Loading } from "../../Loading/loading";
import { dateToMonth } from "../../../lib/actions";

function AddBill() {
  const [species, setSpecies] = useState([]);
  const [socios, setSocios] = useState([{}]);
  const [bill, setBill] = useState({
    socio_id: 0,
    date: "",
    species: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/getStock", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => setSpecies(resJ))
      .catch((err) => console.log(err))
      .finally(() => {
        fetch("https://flor-charrua-backend.vercel.app/admin/getsocios", {
          method: "GET",
          headers: {
            auth_token: localStorage.getItem("auth_token"),
          },
        })
          .then((res) => res.json())
          .then((resJ) => {
            setSocios(resJ);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      });
  }, []);

  const manageBill = (e, product) => {
    e.preventDefault();
    const target = e.target;
    if (target.name === "member") {
      setBill({ ...bill, socio_id: target.value });
    }
    if (target.name === "species") {
      setBill({
        ...bill,
        species: [...bill.species, { product: target.value, amount: 0 }],
      });
    }
    if (target.name === "date") {
      setBill({ ...bill, date: target.value });
    }
    if (target.name === "product") {
      const newBill = { ...bill };
      // Encontrar el índice del producto en el array species
      const productIndex = bill.species.findIndex((i) => i.product === product);
      // Verificar si se encontró el producto
      if (productIndex !== -1) {
        // Actualizar la cantidad del producto en el nuevo objeto bill
        newBill.species[productIndex].amount = target.value;
        // Actualizar el estado con el nuevo objeto bill
        setBill(newBill);
      }
    }
    console.log(bill);
  };

  return (
    <>
      <Header goBack />
      <div className="bill">
        {loading ? (
          <Loading />
        ) : (
          <main className="main-bills">
            <h2>Flor Charrúa</h2>
            <input
              className="date"
              name="date"
              type="date"
              onChange={(e) => manageBill(e)}
            />
            <div className="month">
              <label htmlFor="month">Mes de entrega: </label>
              <input name="month" disabled value={dateToMonth(bill.date)} />
            </div>
            <div className="member">
              <label htmlFor="member">Socio: </label>
              <select name="member" onChange={(e) => manageBill(e)}>
                <option>Elegir socio</option>
                {socios.map((s, k) => {
                  return (
                    <option key={k} value={s.id}>
                      {s.name} {s.last_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="species">
              <label htmlFor="species">Especies</label>
              <select
                name="species"
                value="Agregar"
                onChange={(e) => manageBill(e)}
              >
                <option value="Agregar">Agregar</option>
                {species.map((s, k) => {
                  let show = !bill.species.some((i) => {
                    return (
                      i.product === s.species.replace(/ /g, "").toLowerCase()
                    );
                  });
                  if (show) {
                    return (
                      <option
                        key={k}
                        value={s.species.replace(/ /g, "").toLowerCase()}
                      >
                        {s.species}
                      </option>
                    );
                  }
                  return;
                })}
              </select>
            </div>
            <section className="species-table">
              <div className="titles">
                <p>Especie</p>
                <p>Cantidad</p>
              </div>
              <div className="resume">
                {bill.species.map((s, k) => {
                  return (
                    <>
                      <label>
                        {s.product.charAt(0).toUpperCase() + s.product.slice(1)}
                      </label>
                      <input
                        name="product"
                        value={bill.species[k].amount}
                        type="number"
                        onChange={(e, product) => manageBill(e, s.product)}
                      />
                    </>
                  );
                })}
              </div>
            </section>
            <button className="upload">SUBIR</button>
          </main>
        )}
      </div>
    </>
  );
}

export default AddBill;
