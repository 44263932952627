import "./Input.css";

function Input({ id, name, type, placeholder, value, onChange }) {
  return (
    <input
      id={id}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

export default Input;
