import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../General/Button";
import Header from "../../Header/Header";
import "./AdminMain.css";
import { Loading } from "../../Loading/loading";

function AdminMain() {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        setErr(resJ.msg);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <Loading />
  ) : err === "Authorized" ? (
    <>
      <Header headerMiddle="Admin" />
      <main className="admin-main">
        <Button
          text="Checklist de Tareas"
          onClick={() => navigate("/admin/todos")}
        />
        <Button text="Ordenes" onClick={() => navigate("/admin/orders")} />
        <Button text="Cosecha" onClick={() => navigate("/admin/harvestfile")} />
        <Button text="Gastos" onClick={() => navigate("/admin/expensefile")} />
        <Button
          text="Administrar Stock"
          onClick={() => navigate("/admin/stock")}
        />
      </main>
    </>
  ) : (
    <p>Error 401: UnAuthorized</p>
  );
}

export default AdminMain;
