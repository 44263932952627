import { useEffect, useState } from "react";
import { Loading } from "../../Loading/loading";

function WorkTable({ thisDate, data, thisData }) {
  const [thick, setThick] = useState(0);
  const [thin, setThin] = useState(0);
  const [modal, setModal] = useState({
    state: false,
    data: {},
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    for (let i = 0; i < thisData.length; i++) {
      if (thisData[i].type === "thick")
        setThick((t) => {
          return t + Math.round(parseFloat(thisData[i].hours));
        });
      if (thisData[i].type === "thin")
        setThin((t) => {
          return t + Math.round(parseFloat(thisData[i].hours));
        });
    }
    setLoading(false);
  }, []);

  const deleteWork = (data) => {
    fetch("https://flor-charrua-backend.vercel.app/admin/deleteWork", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        auth_token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify({
        hours: data.hours,
        date: data.date,
        no_socio: data.no_socio,
        type: data.type,
      }),
    })
      .then((res) => res.json())
      .then((resJson) => {})
      .catch((err) => {
        console.error(err);
      })
      .finally();
  };

  return loading ? (
    <Loading />
  ) : (
    <section className="work-table">
      <h3>
        Fecha:
        <span> {thisDate}</span>
      </h3>
      {thick > 0 ? (
        <div>
          <h4>Manicura gruesa</h4>
          {thisData.map((d, k) => {
            return d.type === "thick" ? (
              <div key={k}>
                <p>
                  Nombre:{" "}
                  <span>{d.socio_name ? d.socio_name : d.no_socio}</span>
                </p>
                <p>
                  Horas: <span>{d.hours}hs</span>
                </p>
                <button
                  className="delete"
                  onClick={() => setModal({ state: true, data: d })}
                >
                  X
                </button>
              </div>
            ) : null;
          })}
          <p className="total">
            Total Hours: <span>{thick.totalHours}hs</span>
          </p>
        </div>
      ) : null}
      {thin > 0 ? (
        <div>
          <h4>Manicura fina</h4>
          {thisData.map((d, k) => {
            return d.type === "thin" ? (
              <div key={k}>
                <p>
                  Nombre:{" "}
                  <span>{d.socio_name ? d.socio_name : d.no_socio}</span>
                </p>
                <p>
                  Horas: <span>{d.hours}hs</span>
                </p>
                <button
                  className="delete"
                  onClick={() => setModal({ state: true, data: d })}
                >
                  X
                </button>
              </div>
            ) : null;
          })}
          <p className="total">
            Total Hours: <span>{thin}hs</span>
          </p>
        </div>
      ) : null}
      {modal.state ? (
        <div className="modal">
          <div className="modal-content">
            <p>¿Está seguro que desea eliminar este trabajo?</p>
            <button
              style={{ "background-color": "rgba(255, 0, 0, 0.733)" }}
              onClick={() => deleteWork(modal.data)}
            >
              Si
            </button>
            <button onClick={() => setModal(false)}>No</button>
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default WorkTable;
