import "./TextInput.css";

function TextInput({
  text,
  inputName,
  inputType,
  inputValue,
  onInput,
  disabled,
}) {
  return (
    <div>
      <p>{text}</p>
      <input
        name={inputName}
        type={inputType}
        value={inputValue}
        onInput={onInput}
        disabled={disabled}
      />
    </div>
  );
}

export default TextInput;
