export const newsData = [
  {
    new: "Brasil despenaliza el uso de marihuana!?",
    src: "/News/brfaso.jpg",
    link: "https://www.infobae.com/america/america-latina/2024/06/25/el-tribunal-supremo-de-brasil-descriminalizo-el-consumo-de-marihuana-en-el-pais/",
  },
  {
    new: "Legalizado entre críticas el uso recreativo del cannabis en Alemania para mayores de 18 años",
    src: "/News/alemaniacannabis.jpg",
    link: "https://www.elmundo.es/internacional/2024/04/01/660a50c3fc6c8315188b457c.html",
  },
  {
    new: "Tailandia toma medidas para prohibir el consumo recreativo de cannabis",
    src: "/News/tainew.jpg",
    link: "https://cnnespanol.cnn.com/2024/01/10/tailandia-prohibir-consumo-cannabis-18-meses-despues-despenalizacion-historica-trax/",
  },
];

export const todos = {
  floracion: [
    "Chequeo funcionamiento extractor F1",
    "Luces de Verdes F1 y F2 apagadas",
    "Chequeo funcionamiento AA F1 y F2 (Split en horario ON)",
    "Ventiladores F1 y F2 prendidos",
    "Puertas F1 y F2 cerradas y trancadas",
    "Pulverizar con IPM",
    "Pulverizar con Neem",
    "Pulverizar con Jabón Potásico",
    "Pulverizar con K40",
    "Pulverizar con Agua",
    "Poda BA//AA",
  ],
  preparto: [
    "Ventiladores en condiciones",
    "LEDs en funcionamiento esquejes",
    "LEDs prendidos pre parto",
    "Bandejas en condiciones y cerradas",
    "Pulverizar con IPM",
    "Pulverizar con Neem",
    "Pulverizar con Jabón Potásico",
    "Pulverizar con K40",
    "Pulverizar con Agua",
    "Poda BA//AA",
  ],
  crecimiento: [
    "Chequeo funcionamiento AA all day",
    "Ventiladores paredes all day",
    "LEC prendidos 2300 - 1700",
    "Bandejas en condiciones",
    "Puerta cerrada",
    "Pulverizar con IPM",
    "Pulverizar con Neem",
    "Pulverizar con Jabón Potásico",
    "Pulverizar con K40",
    "Pulverizar con Agua",
    "Poda BA//AA",
  ],
  antes_de_salir: [
    "Sala de Maquinas cerrada",
    "Puertas azotea cerrada y con candados",
    "Ventanas de Oficina cerradas y cortina baja",
    "Luces apagadas del baño, hall, tiras LED",
    "Chequear acopio faCircleRadiation, deshumificador ON s/c",
    "Dejar herramientas de trabajo en su lugar",
    "Dejar todo limpio y ordenado",
    "Llave de agua cerrada",
  ],
};
