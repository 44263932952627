import "./Expense.css";
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";
import TextInput from "../../General/TextInput";
import Header from "../../Header/Header";
import Button from "../../General/Button";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Loading/loading";

function Expense() {
  const [exp, setExp] = useState({
    expense: "",
    amount: 0,
    comment: "",
    date: "",
  });
  const [err, setErr] = useState("");
  const expenses = [
    "UTE",
    "OSE",
    "Alquiler",
    "Ferretería",
    "Fertilizantes",
    "Turba",
    "Sueldos",
    "Varios",
  ];
  const [media, setMedia] = useState([]);
  const [userID, setUserID] = useState("");
  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_ANON_KEY
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const manageExpense = (e) => {
    setExp({ ...exp, [e.target.name]: e.target.value });
  };
  const uploadForm = (e) => {
    e.preventDefault();
    fetch("https://flor-charrua-backend.vercel.app/admin/addExpense", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        auth_token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify(exp),
    })
      .catch((err) => {
        setErr("Error al subir gasto");
        console.log(err);
      })
      .finally(() => {});
  };

  const getUser = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user !== null) {
        setUserID(user.id);
        setLoading(false);
      } else {
        setUserID("");
        setLoading(false);
      }
    } catch (e) {}
  };

  const getMedia = async () => {
    const { data, err } = await supabase.storage
      .from("Bills")
      .list(userID + "/", {
        offset: 0,
        sortBy: {
          column: "name",
          order: "asc",
        },
      });
    if (data) {
      setMedia(data);
    } else {
      console.log("Error", err);
    }
  };

  const uploadImage = async (e) => {
    let file = e.target.files[0];

    const { data, err } = await supabase.storage
      .from("Bills")
      .upload(
        userID +
          "/" +
          exp.expense +
          "/" +
          exp.date.split("-").reverse().join("-").slice(3) +
          "/" +
          uuidv4(),
        file
      );
    if (data) {
      getMedia();
    } else {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
    getMedia();
  }, [userID]);

  if (loading) {
    return <Loading />;
  } else if (userID === "") {
    navigate("/admin/supabase");
  } else
    return (
      <>
        <Header headerMiddle="Gastos" />
        <form
          className="expense-form"
          onSubmit={(e) => {
            if (exp.expense === "") {
              setErr("Debe elegir un gasto");
              return;
            } else if (exp.amount === 0) {
              setErr("Debe ingresar un importe");
              return;
            } else if (exp.date === "") {
              setErr("Debe elegir una fecha");
              return;
            } else {
              uploadForm(e);
              setExp({
                expense: "",
                amount: 0,
                comment: "",
                date: "",
              });
            }
          }}
        >
          <select
            name="expense"
            defaultValue=""
            onChange={(e) => manageExpense(e)}
          >
            <option value="">Elegir gasto</option>
            {expenses.map((e, k) => {
              return (
                <option key={k} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
          <TextInput
            text="Importe"
            inputName="amount"
            inputType="number"
            inputValue={exp.amount}
            onInput={(e) => manageExpense(e)}
          />
          <TextInput
            text="Comentario"
            inputName="comment"
            inputType="text"
            inputValue={exp.comment}
            onInput={(e) => manageExpense(e)}
          />
          <TextInput
            text="Fecha"
            inputName="date"
            inputType="date"
            onInput={(e) => {
              manageExpense(e);
            }}
          />
          {exp.expense && exp.amount && exp.date ? (
            <TextInput
              text="Foto de la factura"
              inputType="file"
              onInput={(e) => uploadImage(e)}
            />
          ) : null}
          {/* {media.map((e, k) => {
            return (
              <img
                key={k}
                src={`https://mfgcraomtlxihivkqmhl.supabase.co/storage/v1/object/public/Bills/84ce0f34-db88-4559-909d-0bdc55374c8a/${e.name}`}
              />
            );
          })} */}
          {err ? <p className="error">{err}</p> : null}
          <Button text="Subir" type="submit" />
        </form>
        ;
      </>
    );
}

export default Expense;
