import "./AdminFiles.css";
import { useNavigate } from "react-router-dom";
import Button from "../../General/Button";
import Header from "../../Header/Header";
import Manicure from "../Manicure/Manicure";
import { useState } from "react";

export default function HarvestFile() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  return (
    <>
      <Header goBack />
      <main className="files-main">
        <Button
          text="Ver Cosecha"
          onClick={() => navigate("/admin/harvests")}
        />
        <Button
          text="Agregar Cosecha"
          onClick={() => navigate("/admin/harvests/form")}
        />
        <Button text="Trabajo de manicura" onClick={() => setModal(!modal)} />
        <Button
          text="Trabajo hecho"
          onClick={() => navigate("/admin/workdone")}
        />
        {modal ? <Manicure setModal={setModal} /> : null}
      </main>
    </>
  );
}
