import { useNavigate } from "react-router-dom";

function Dropdown({ items, dropdown }) {
  const navigate = useNavigate();

  return (
    <ul className={`dropdown ${dropdown ? "show" : ""}`}>
      {items.map((i, k) => {
        return (
          <li key={k} className="menu-items">
            <a onClick={() => navigate(i.url)}>{i.title}</a>
          </li>
          //El url se cambia desde el manuItems.js dentro de src
        );
      })}
    </ul>
  );
}

export default Dropdown;
