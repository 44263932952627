import "./Header.css";
import menuItems from "./Menu/menuItems";
import Menu from "./Menu/Menu";
import Cart from "./Cart/Cart";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import LogOutModal from "../Login/LogoutModal";

function Header({ headerMiddle, goBack }) {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [items, setItems] = useState(menuItems);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        if (resJ.msg !== "Authorized") {
          setItems((i) => i.filter((f) => !f.admin));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <header className="main-header">
      <nav>
        <Menu items={items} goBack={goBack} admin={admin} />
      </nav>
      {/* <div className="header-middle">{headerMiddle}</div> */}
      <nav>
        <Cart
          cartOnClick={() => {
            navigate("/cart");
          }}
        />
        <FontAwesomeIcon
          className="open-lo"
          icon={faArrowRightFromBracket}
          onClick={() => setModal(true)}
        />
      </nav>
      {modal && <LogOutModal setModal={setModal} />}
    </header>
  );
}

export default Header;
