import { useEffect, useState } from "react";
import CartComponents from "./CartComponents";
import "./ShopingSummary.css";

function ShopingSummary({ cart, setCart, setPreorder, preorder }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/cart", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        setCart(
          resJ.map((r) => {
            let thisAmount =
              preorder[r.species.replace(/\s+/g, "").toLowerCase()];
            if (!thisAmount)
              return {
                species: "",
                amount: null,
                price: null,
              };
            return {
              species: r.species,
              amount: thisAmount,
              price: r.price,
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    setTimeout(() => {
      sessionStorage.setItem("preorder", JSON.stringify(preorder));
    }, 100);
  }, [preorder]);

  return (
    <div className="summary">
      {loading ? (
        <img className="loading" src="/Imgs/joint.gif" />
      ) : (
        <div>
          {cart.map((i, k) => {
            if (i.species === "") return;
            return (
              <CartComponents
                key={k}
                species={i.species}
                amount={i.amount}
                price={i.price}
                setPreorder={setPreorder}
                preorder={preorder}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ShopingSummary;
