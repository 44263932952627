// import { Link } from "react-router-dom";
import { Carousel, CarouselItem } from "../Carousel/Carousel";
import Header from "../Header/Header";
import "./Main.css";

function Main() {
  return (
    <>
      <Header headerMiddle="INICIO" />
      <div className="main-main">
        <Carousel>
          <CarouselItem>
            <img src="/Carousel/promo3.jpg" alt="Imagen promocional"></img>
          </CarouselItem>
          <CarouselItem>
            <img src="/Carousel/promo2.jpg" alt="Imagen promocional"></img>
          </CarouselItem>
          <CarouselItem>
            <img src="/Carousel/promo1.jpg" alt="Imagen promocional"></img>
          </CarouselItem>
        </Carousel>
        {/* <Link
          to={"/carta"}
          className="sec"
          style={{ backgroundImage: 'URL("catalog.png")' }}
        >
          <span>CATÁLOGO</span>
        </Link> */}
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Main;
