import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LogoutModal.css";
import { faCross, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function LogOutModal({ setModal }) {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("auth_token");
    setModal(false);
    navigate("/login");
  };
  return (
    <main className="main-logout">
      <section className="modal-logout">
        <FontAwesomeIcon
          className="xmark"
          icon={faXmark}
          onClick={() => setModal(false)}
        />
        <p>Querés cerrar sesión?</p>
        <button onClick={() => logOut()}>CERRAR SESIÓN</button>
      </section>
    </main>
  );
}

export default LogOutModal;
