import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import "./Confirmation.css";

function Confirmation() {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <main className="confirmation-main">
        <h2>PEDIDO CONFIRMADO!</h2>
        <p>GRACIAS Y BUEN VIAJE</p>
        <img src="/logo.png"></img>
        <button onClick={() => navigate("/")}>VOLVER AL INICIO</button>
      </main>
    </>
  );
}
export default Confirmation;
