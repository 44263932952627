import { useState } from "react";
import { Loading } from "../../Loading/loading";

function StockEditModal({ id, species, price, available, setModal }) {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState({
    price: price,
    available: available,
  });

  const manageInputs = (e) => {
    if (e.target.name === "available") {
      setEdit({ ...edit, available: e.target.checked });
    } else setEdit({ ...edit, [e.target.name]: e.target.value });
  };

  const sendNewStock = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`https://flor-charrua-backend.vercel.app/admin/editStock/${id}`, {
      method: "PATCH",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(edit),
    })
      .then((res) => res.json())
      .then((resJ) => {
        console.log(resJ);
      })
      .catch((err) => console.log("ERROR AL EDITAR", err))
      .finally(() => window.location.reload());
  };

  return (
    <section className="stock-modal">
      <div className="stock-modal-div">
        <h3>{species}</h3>
        <div className="modal-input">
          <label htmlFor="price">Precio</label>
          <input
            type="number"
            name="price"
            value={edit.price}
            onChange={(e) => manageInputs(e)}
          />
        </div>
        <div className="modal-input">
          <label htmlFor="available">Disponible</label>
          <input
            type="checkbox"
            name="available"
            checked={edit.available}
            onChange={(e) => manageInputs(e)}
          />
        </div>
        <button onClick={() => setModal(false)}>Canelar</button>
        <button onClick={(e) => sendNewStock(e)} disabled={loading}>
          {loading ? "Cargando..." : "Guardar"}
        </button>
      </div>
    </section>
  );
}

export default StockEditModal;
