import { useEffect } from "react";
import "./Stock.css";
import { useState } from "react";
import { Loading } from "../../Loading/loading";
import ProductStock from "./ProductStock";
import Header from "../../Header/Header";

function Stock() {
  const [stock, setStock] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/getStock", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => setStock(resJ))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header goBack />
      <main className="stock-main">
        {loading ? (
          <Loading />
        ) : (
          stock.map((p, k) => {
            return (
              <ProductStock
                key={k}
                id={p.id}
                species={p.species}
                price={p.price}
                available={p.available}
              />
            );
          })
        )}
      </main>
    </>
  );
}

export default Stock;
