import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Cart.css";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

function Cart({ cartOnClick }) {
  return (
    <FontAwesomeIcon
      icon={faCartShopping}
      className="header-cart"
      src="/Imgs/cart.png"
      alt="Cart Icon"
      onClick={cartOnClick}
    />
  );
}

export default Cart;

//commit
