import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Counter from "../General/Counter";
import Cart from "../Header/Cart/Cart";

function Product({
  productMainImg,
  product,
  setPreorder,
  preorder,
  outOfStock, //boolean
  setAdded,
}) {
  const kief = product.species == "Kief";
  const [addedThisOne, setAddedThisOne] = useState(false);

  useEffect(() => {
    let isAdded =
      preorder[product.species.toLowerCase().replace(/\s/g, "")] > 0;
    setAddedThisOne(isAdded);
  }, []);

  const submitPurchase = (e) => {
    e.preventDefault();
    setAdded(true);
    setAddedThisOne(true);
    let name = product.species.replace(/\s+/g, "").toLowerCase();
    setPreorder({ ...preorder, [name]: 5 });
    sessionStorage.setItem("preorder", JSON.stringify(preorder));
  };

  return (
    <section className="product">
      <Link to={`/products/${product.species}`}>
        <div className="product-imgs">
          {kief ? (
            <span>Kief</span>
          ) : (
            <img className="main-img" src={productMainImg} />
          )}
        </div>
      </Link>
      <form>
        <p className="product-name">{product.species}</p>
        <div className="specs">
          {product.sativa && (
            <p>
              Sativa/Indica: {product.sativa}%/{product.indica}%
            </p>
          )}
          {product.thc && (
            <p>
              THC/CBD: {product.thc}%/{product.cbd}%
            </p>
          )}
        </div>
        <p className="price">${product.price}</p>
        {!addedThisOne ? (
          <button className="add-button" onClick={(e) => submitPurchase(e)}>
            AGREGAR AL CARRITO
          </button>
        ) : (
          <Link to={"/cart"} className="add-button">
            Agregado!
          </Link>
        )}

        {/* <Cart cartOnClick={submitPurchase} /> */}
      </form>
      {outOfStock && product.species != "Kief" ? (
        <div className="outOfStock"></div>
      ) : null}
    </section>
  );
}

export default Product;
