import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import "./ShowExpense.css";
import ExpenseTable from "./ExpenseTable";
import { Loading } from "../../Loading/loading";

function ShowExpense() {
  const [expenses, setExpenses] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [thisData, setThisData] = useState([]);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Setiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/getExpenses", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        setExpenses(resJ.res);
        for (let i = 0; i < resJ.dates.length; i++) {
          addDate(
            true,
            `${new Date(resJ.dates[i]).getMonth() + 1}, ${new Date(
              resJ.dates[i]
            ).getFullYear()}`
          );
        }
      })
      .then(() => {
        addDate(false, undefined);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setThisData(
      expenses.filter(
        (d) =>
          new Date(d.date).getMonth() + 1 === selected.split(", ")[0] &&
          new Date(d.date).getFullYear() === selected.split(", ")[1]
      )
    );
  }, [selected]);

  const addDate = (bool, value) => {
    if (bool) {
      setDates((d) => [...d, value]);
    } else {
      setDates((d) => {
        return d.filter((value, index) => {
          return d.indexOf(value) === index;
        });
      });
    }
  };

  const manageSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <>
      <Header headerMiddle={"Ver Gastos"} />
      {loading ? (
        <Loading />
      ) : (
        <main className="main-expenses">
          <select value={selected} onChange={(e) => manageSelect(e)}>
            <option value="" disabled>
              Elige el mes
            </option>
            {dates.map((d, k) => {
              return (
                <option key={k} value={d}>
                  {months[d.split(", ")[0] - 1]}, {d.split(", ")[1]}
                </option>
              );
            })}
          </select>
          {selected ? (
            <ExpenseTable data={thisData} thisDate={selected} months={months} />
          ) : undefined}
        </main>
      )}
    </>
  );
}

export default ShowExpense;
