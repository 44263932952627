import { useEffect, useState } from "react";
import Button from "../../General/Button";
import TextInput from "../../General/TextInput";
import Header from "../../Header/Header";
import "./HarvestForm.css";
import { Loading } from "../../Loading/loading";

function HarvestForm() {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [harvests, setHarvests] = useState([]);
  const [products, setProducts] = useState([]);
  const [actualHarvest, setActualHarvest] = useState({
    newHarvest: true,
    species: undefined,
    number_of_plants: undefined,
    waste: undefined,
    stock: undefined,
    harvest_date: undefined,
    sketched_date: undefined,
    first_transplantation: undefined,
    second_transplantation: undefined,
    third_transplantation: undefined,
    bloom_date: undefined,
    observations: undefined,
    finished: false,
  });

  useEffect(() => {
    setLoading(true);
    fetch("https://flor-charrua-backend.vercel.app/admin/harvests", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        if (resJ.msg === "Unauthorized") {
          setStatus(resJ.msg);
          return;
        }
        setHarvests(resJ.res);
      })
      .catch((err) => console.log(err))
      .finally(() => setStatus("Authorized"));

    fetch("https://flor-charrua-backend.vercel.app/admin/allproducts", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        setProducts(resJ);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
    console.log(products);
  }, []);

  const manageInformation = (e) => {
    setActualHarvest({ ...actualHarvest, [e.target.name]: e.target.value });
  };
  const manageCheckbox = (e) => {
    if (e.target.checked) {
      setActualHarvest({
        newHarvest: true,
        species: "",
        number_of_plants: 0,
        waste: 0,
        stock: 0,
        harvest_date: "",
        sketched_date: "",
        first_transplantation: "",
        second_transplantation: "",
        third_transplantation: "",
        bloom_date: "",
        observations: "",
      });
    } else {
      setActualHarvest({ ...actualHarvest, newHarvest: false });
    }
  };
  const editHarvest = (e) => {
    let thisHarvest = harvests.filter((f) => {
      return f.sketched_date === e.target.value;
    });
    thisHarvest = thisHarvest[0];
    console.log(thisHarvest);

    setActualHarvest({
      newHarvest: false,
      species: thisHarvest.species,
      number_of_plants: thisHarvest.number_of_plants
        ? thisHarvest.number_of_plants
        : 0,
      waste: thisHarvest.waste ? thisHarvest.waste : 0,
      stock: thisHarvest.stock ? thisHarvest.stock : 0,
      harvest_date: thisHarvest.harvest_date
        ? thisHarvest.harvest_date.split("T")[0]
        : "",
      sketched_date: thisHarvest.sketched_date.split("T")[0],
      first_transplantation: thisHarvest.first_transplantation
        ? thisHarvest.first_transplantation.split("T")[0]
        : "",
      second_transplantation: thisHarvest.second_transplantation
        ? thisHarvest.second_transplantation.split("T")[0]
        : "",
      third_transplantation: thisHarvest.third_transplantation
        ? thisHarvest.third_transplantation.split("T")[0]
        : "",
      bloom_date: thisHarvest.bloom_date
        ? thisHarvest.bloom_date.split("T")[0]
        : "",
      observations: thisHarvest.observations ? thisHarvest.observations : "",
    });
  };

  const updateHarvest = () => {
    fetch(
      `https://flor-charrua-backend.vercel.app/admin/addharvest/${
        actualHarvest.newHarvest ? "post" : "patch"
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          auth_token: localStorage.getItem("auth_token"),
        },
        body: JSON.stringify(actualHarvest),
      }
    )
      .then(() => {
        setActualHarvest({
          newHarvest: true,
          species: "",
          number_of_plants: 0,
          waste: 0,
          stock: 0,
          harvest_date: "",
          sketched_date: "",
          first_transplantation: "",
          second_transplantation: "",
          third_transplantation: "",
          bloom_date: "",
          observations: "",
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Header goBack={true} headerMiddle="Cosecha" />
      <main className="harvest-form">
        <div className="new-harvest-div">
          <p>Nueva cosecha</p>
          <input
            name="newHarvest"
            className="checkbox"
            type="checkbox"
            checked={actualHarvest.newHarvest}
            onChange={(e) => manageCheckbox(e)}
          />
        </div>
        {actualHarvest.newHarvest ? (
          <select
            name="species"
            value={actualHarvest.species}
            onChange={(e) => manageInformation(e)}
          >
            <option value="" disabled>
              Especie
            </option>
            {products.map((p, k) => {
              return (
                <option key={k} value={p.species}>
                  {p.species}
                </option>
              );
            })}
          </select>
        ) : (
          <select
            onChange={(e) => editHarvest(e)}
            // value={actualHarvest.sketched_date}
          >
            <option value="" disabled>
              Fecha de esqueje
            </option>
            {harvests.map((h, k) => {
              return (
                <option key={k} value={h.sketched_date}>
                  {`${h.species}: ${h.sketched_date
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/")}`}
                </option>
              );
            })}
          </select>
        )}
        <TextInput
          text="Fecha de esquejado"
          inputName="sketched_date"
          inputType="date"
          inputValue={actualHarvest.sketched_date}
          onInput={(e) => manageInformation(e)}
          disabled={!actualHarvest.newHarvest}
        />
        <TextInput
          text="Cantidad de plantas"
          inputName="number_of_plants"
          inputType="number"
          inputValue={actualHarvest.number_of_plants}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Primer transplante"
          inputName="first_transplantation"
          inputType="date"
          inputValue={actualHarvest.first_transplantation}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Segundo transplante"
          inputName="second_transplantation"
          inputType="date"
          inputValue={actualHarvest.second_transplantation}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Tercer transplante"
          inputName="third_transplantation"
          inputType="date"
          inputValue={actualHarvest.third_transplantation}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Fecha de floacion"
          inputName="bloom_date"
          inputType="date"
          inputValue={actualHarvest.bloom_date}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Fecha de cosecha"
          inputName="harvest_date"
          inputType="date"
          inputValue={actualHarvest.harvest_date}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Stock"
          inputName="stock"
          inputType="number"
          inputValue={actualHarvest.stock}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Desecho"
          inputName="waste"
          inputType="number"
          inputValue={actualHarvest.waste}
          onInput={(e) => manageInformation(e)}
        />
        <TextInput
          text="Observaciones"
          inputName="observations"
          inputType="text"
          inputValue={actualHarvest.observations}
          onInput={(e) => manageInformation(e)}
        />
        <Button
          text={
            actualHarvest.newHarvest ? "Agregar cosecha" : "Actualizar cosecha"
          }
          onClick={() => updateHarvest()}
        />
      </main>
    </>
  );
}

export default HarvestForm;
