import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./Components/Login/Login";
import Main from "./Components/Main/Main";
import Carta from "./Components/Carta/Carta";
import Cart from "./Components/Cart/Cart";
import ProductSpecification from "./Components/ProductSpecification/ProductSpecification";
import AboutUs from "./Components/AboutUs/AboutUs";
import News from "./Components/News/News";
import { useEffect, useState } from "react";
import Confirmation from "./Components/Confirmation/Confirmation";
import ChangePass from "./Components/ChangePass/ChangePass";
import HarvestForm from "./Components/Admin/HarvestForm/HarvestForm";
import AdminMain from "./Components/Admin/Main/AdminMain";
import Harvest from "./Components/Admin/Harvest/Harvest";
import Workdone from "./Components/Admin/Workdone/Workdone";
import Orders from "./Components/Admin/Orders/Orders";
import Expense from "./Components/Admin/Expense/Expense";
import Supabase from "./Components/Supabase/Supabase";
import ShowExpense from "./Components/Admin/ShowExpense/ShowExpense";
// import ContactUs from "./Components/Footer/Footer";
// import DatePicker from "./Components/General/DatePicker/DatePicker";
import { Loading } from "./Components/Loading/loading";
import Stock from "./Components/Admin/Stock/Stock";
import AddBill from "./Components/Admin/Bills/AddBill";
import Todos from "./Components/Admin/Todos/Todos";
import ExpensesFile from "./Components/Admin/AdminFiles/ExpensesFile";
import HarvestFile from "./Components/Admin/AdminFiles/HarvestFile";

function App() {
  const [preorder, setPreorder] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("auth_token")) navigate("/login");
    let storagedPreorder = JSON.parse(sessionStorage.getItem("preorder"));
    if (storagedPreorder) setPreorder(storagedPreorder);
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/ping" element={<p>Pong</p>} />
        <Route path="/login" element={<Login />} />
        <Route path="/changepassword" element={<ChangePass />} />
        <Route
          path="/carta"
          element={<Carta setPreorder={setPreorder} preorder={preorder} />}
        />
        <Route
          path="/cart"
          element={<Cart setPreorder={setPreorder} preorder={preorder} />}
        />
        <Route path="/products/:product" element={<ProductSpecification />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/news" element={<News />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/admin" element={<AdminMain />} />
        <Route path="/admin/harvests" element={<Harvest />} />
        <Route path="/admin/harvests/form" element={<HarvestForm />} />
        <Route path="/admin/workdone" element={<Workdone />} />
        <Route path="/admin/orders" element={<Orders />} />
        <Route path="/admin/expense" element={<Expense />} />
        <Route path="/admin/supabase" element={<Supabase />} />
        <Route path="/admin/showExpenses" element={<ShowExpense />} />
        <Route path="/admin/stock" element={<Stock />} />
        <Route path="/admin/bills" element={<AddBill />} />
        <Route path="/admin/todos" element={<Todos />} />
        <Route path="/prueba" element={<Loading />} />
        <Route path="/admin/expensefile" element={<ExpensesFile />} />
        <Route path="/admin/harvestfile" element={<HarvestFile />} />

        <Route path="*" element={<p>404</p>} />
      </Routes>
    </div>
  );
}

export default App;
