import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import "./Workdone.css";
import WorkTable from "./WorkTable";
import { FindNav } from "../../../utilities.js";
import { Loading } from "../../Loading/loading.js";

function Workdone() {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [thisData, setThisData] = useState([]);

  useEffect(() => {
    setLoading(true);
    console.log(FindNav());
    setThisData(
      data.filter(
        (d) =>
          new Date(d.date).getDate() + 1 === new Date(selectedDate).getDate() &&
          new Date(d.date).getMonth() === new Date(selectedDate).getMonth() &&
          new Date(d.date).getFullYear() ===
            new Date(selectedDate).getFullYear()
      )
    );
    fetch("https://flor-charrua-backend.vercel.app/admin/workdone", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((resJ) => {
        setData(resJ.res);
        setDates(resJ.dates);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [selectedDate]);

  const manageSelect = (e) => {
    setSelectedDate(e.target.value);
  };

  return (
    <>
      <Header goBack={true} headerMiddle="Trabajo" />
      {loading ? (
        <Loading />
      ) : (
        <main className="workdone-main">
          <select onChange={(e) => manageSelect(e)} value={selectedDate}>
            <option value="" disabled>
              Elija Fecha
            </option>
            {dates.map((d, k) => {
              let thisDate = d.split(" ");
              thisDate = thisDate[2] + " " + thisDate[1] + ", " + thisDate[3];
              return (
                <option key={k} value={thisDate}>
                  {thisDate}
                </option>
              );
            })}
          </select>
          {selectedDate ? (
            <WorkTable
              thisDate={selectedDate}
              data={data}
              thisData={thisData}
            />
          ) : null}
        </main>
      )}
    </>
  );
}

export default Workdone;
