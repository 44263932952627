import "./Cart.css";
import Header from "../Header/Header";
import ShopingSummary from "./cartComponents/ShopingSummary";
import Button from "../General/Button";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import DatePicker from "../General/DatePicker/DatePicker";

function Cart({ setPreorder, preorder }) {
  const [tArea, setTArea] = useState("");
  const [date, setDate] = useState({
    day: "",
    time: "",
  });
  const [totaltoPay, setTotalToPay] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(undefined);
  const [user, setUser] = useState({
    name: "",
    last_name: "",
    mail: "",
  });
  const [cart, setCart] = useState([
    {
      species: "",
      amount: null,
      price: null,
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://flor-charrua-backend.vercel.app/getUser", {
      method: "GET",
      headers: {
        auth_token: localStorage.getItem("auth_token"),
      },
    })
      .then((res) => res.json())
      .then((rjson) => {
        setUser({
          name: rjson.name,
          last_name: rjson.last_name,
          mail: rjson.mail,
        });
      })
      .catch((err) => console.log(err))
      .finally();
  }, []);

  useEffect(() => {
    setTotalToPay(0);
    setTotalAmount(0);
    setTotalToPay((t) => {
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].amount) {
          t = t + cart[i].price * cart[i].amount;
        }
      }
      if (t > 0) {
        return t;
      } else {
        return 0;
      }
    });
    setTotalAmount((t) => {
      for (let i = 0; i < cart.length; i++) {
        t = t + cart[i].amount;
      }
      return t;
    });
  }, [cart]);

  const manageTArea = (e) => {
    setTArea(e.target.value);
  };

  const sendMail = (e) => {
    e.preventDefault();
    const templateParams = {
      user_name: user.name,
      user_lastname: user.last_name,
      order: JSON.stringify(preorder),
      total: totaltoPay,
      date: `${new Date(date.day).getDate()}/${
        new Date(date.day).getMonth() + 1
      }/${new Date(date.day).getFullYear()}`,
      hour: `${date.time}`,
      observations: tArea,
      reply_to: user.mail,
    };
    emailjs
      .send(
        "service_v2phykd",
        "template_13qzs2t",
        templateParams,
        "9nlpCd66GTe2nzaxe"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const submitOrder = (e) => {
    setWarning(undefined);
    if (totalAmount > 40) {
      setWarning("La cantidad maxima por socio son 40g");
    } else if (totaltoPay === 0) {
      setWarning("el carrito esta vacío");
    } else if (!date.day || !date.time) {
      setWarning("Se debe elejir dia y hora de la entrega");
    } else {
      setLoading(true);
      fetch("https://flor-charrua-backend.vercel.app/submitorder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          auth_token: localStorage.getItem("auth_token"),
        },
        body: JSON.stringify({
          date: new Date(date.day),
          time: `${date.time.split(":")[0]}:${date.time.split(":")[1]}`,
          total: totaltoPay,
          observations: tArea,
          applecookies: preorder.applecookies,
          despink: preorder.despink,
          superlemonhaze: preorder.superlemonhaze,
          chocolope: preorder.chocolope,
          strawberryamnesia: preorder.strawberryamnesia,
          suguz: preorder.suguz,
          orangecookiemac: preorder.orangecookiemac,
          ogkush: preorder.ogkush,
          kief: preorder.kief,
        }),
      })
        .then((res) => res.json())
        .then((resJ) => {
          if (resJ.msg === "Unauthorized") {
            let gramsLeft = 40 - resJ.gramsPurchased;
            setWarning(`solo te quedan ${gramsLeft}g este mes`);
          } else {
            setPreorder({});
            setWarning("");
            navigate("/confirmation");
          }
        })
        .catch((err) => setWarning("Hubo un problema con los servidores"))
        .finally(() => {
          sendMail(e);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Header headerMiddle={<p>Carrito</p>} goBack />
      <main className="main-cart">
        <h2>TU CARRITO</h2>
        <ShopingSummary
          cart={cart}
          setCart={setCart}
          setPreorder={setPreorder}
          preorder={preorder}
        />
        <div className="ta-div">
          <textarea
            placeholder="Comentarios..."
            maxLength="250"
            value={tArea}
            onChange={(e) => manageTArea(e)}
          ></textarea>
        </div>
        <div className="shoping-date">
          <DatePicker date={date} setDate={setDate} />
        </div>
        <p className="schedual">
          Agendado para:{" "}
          <span>
            {date.day && date.day ? (
              <div>
                {date.time}
                {"hs "}
                del {new Date(date.day).getDate()}/
                {new Date(date.day).getMonth()}
              </div>
            ) : undefined}
          </span>
        </p>
        <p className="total">
          Total a pagar: <span>${totaltoPay} </span>{" "}
        </p>
        {warning ? <p className="warning">*{warning}</p> : null}
        <Button
          text={loading ? "Cargando..." : "ENVIAR PEDIDO"}
          className="button"
          onClick={(e) => submitOrder(e)}
        />
      </main>
      {/* <Footer /> */}
    </>
  );
}

export default Cart;
