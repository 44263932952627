import "./ChangePass.css";
import Footer from "../Footer/Footer";
import Button from "../General/Button";
import Input from "../General/Input";
import Header from "../Header/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function ChangePass() {
  const [inputValues, setInputValues] = useState({
    oldPass: "",
    newPass: "",
    repPass: "",
  });

  const navigate = useNavigate();

  const manageInput = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const submitChanges = (e) => {
    e.preventDefault();
    fetch("https://flor-charrua-backend.vercel.app/changepassword", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        auth_token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify({
        oldPass: inputValues.oldPass,
        newPass: inputValues.newPass,
        repPass: inputValues.repPass,
      }),
    })
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.msg === "Bad Requrest") {
          return;
        }
        if (resJson.msg === "Internal Server Error") {
          return;
        }
        navigate("/");
      });
  };

  return (
    <>
      <Header />
      <main className="change-pass">
        <form onSubmit={(e) => submitChanges(e)}>
          <Input
            name={"oldPass"}
            type={"text"}
            placeholder={"Contraseña vieja"}
            value={inputValues.oldPass}
            onChange={(e) => manageInput(e)}
          />
          <Input
            name={"newPass"}
            type={"password"}
            placeholder={"Nueva contraseña"}
            value={inputValues.newPass}
            onChange={(e) => manageInput(e)}
          />
          <Input
            name={"repPass"}
            type={"password"}
            placeholder={"Repetir contraseña"}
            value={inputValues.repPass}
            onChange={(e) => manageInput(e)}
          />
          <Button text={"Aceptar"} type={"submit"} />
          <a href="/">Cancelar</a>
        </form>
      </main>
      <Footer />
    </>
  );
}

export default ChangePass;
