function OrderTable({ data, date }) {
  let thisData = data.filter((d) => {
    let thisDate = d.date.split("T")[0].split("-");
    thisDate = thisDate[2] + "/" + thisDate[1] + "/" + thisDate[0];
    return thisDate == date;
  });
  const species = {
    applecookies: "Apple Cookies",
    chocolope: "Chocolope",
    despink: "Despink",
    ogkush: "OG Kush",
    orangecookiemac: "Orange Cookie MAC",
    strawberryamnesia: "Strawberry Amnesia",
    suguz: "Suguz",
    superlemonhaze: "Super Lemon Haze",
    blend: "Blend",
  };

  return (
    <section>
      <h4>Pedidos del {date}</h4>
      {thisData.map((d, k) => {
        let productNames = [];
        Object.keys(d.products).forEach(function (key) {
          productNames.push(key);
        });
        return (
          <div key={k} className="each-order">
            <p>
              Nombre: <span>{d.socio}</span>
            </p>
            <p>
              Hora: <span>{d.hour}</span>
            </p>
            {productNames.map((p, k) => {
              if (d.products[p]) {
                return (
                  <p key={k}>
                    {species[p]}: <span>{d.products[p]}g</span>
                  </p>
                );
              }
            })}
            <p>
              Observaciones: <span>{d.observations}</span>
            </p>
            <p>
              Total a pagar: <span>{d.total}</span>
            </p>
          </div>
        );
      })}
    </section>
  );
}

export default OrderTable;
