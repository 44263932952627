import "./Button.css";

function Button({ text, onClick, type, disabled, className }) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={className}>
      {text}
    </button>
  );
}
export default Button;
