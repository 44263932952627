import { useEffect, useState } from "react";
import "./DatePicker.css";

function DatePicker({ date, setDate }) {
  const [calendar, setCalendar] = useState([]);
  const [loading, setloading] = useState(true);
  const schedual = {
    0: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "19:45"],
    1: ["12:00", "12:30", "13:00", "13:30", "13:45"],
  };

  useEffect(() => {
    let addToCalendar = [];
    for (let i = 0; i < 31; i++) {
      let todayDay = new Date().getDate();
      let todayMonth = new Date().getMonth();
      let todayYear = new Date().getFullYear();
      let daysOfTheMonth = new Date(todayYear, todayMonth, 0).getDate();
      let daysLeft = daysOfTheMonth - todayDay;
      if (daysLeft - i > 0) {
        let futureDay = todayDay + i;
        if (new Date(todayYear, todayMonth, futureDay).getDay() === 2) {
          addToCalendar.push({
            day: "Martes",
            date: `${futureDay}/${todayMonth + 1}`,
            fullDate: new Date(todayYear, todayMonth, futureDay),
          });
        } else if (new Date(todayYear, todayMonth, futureDay).getDay() === 4) {
          addToCalendar.push({
            day: "Jueves",
            date: `${futureDay}/${todayMonth + 1}`,
            fullDate: new Date(todayYear, todayMonth, futureDay),
          });
        } else if (new Date(todayYear, todayMonth, futureDay).getDay() === 5) {
          addToCalendar.push({
            day: "Viernes",
            date: `${futureDay}/${todayMonth + 1}`,
            fullDate: new Date(todayYear, todayMonth, futureDay),
          });
        }
      } else {
        let futureDay = i - daysLeft + 1;
        if (new Date(todayYear, todayMonth + 1, futureDay).getDay() === 2) {
          addToCalendar.push({
            day: "Martes",
            date: `${futureDay}/${todayMonth + 2}`,
            fullDate: new Date(
              todayMonth + 2 <= 12 ? todayYear : todayYear + 1,
              todayMonth + 1,
              futureDay
            ),
          });
        } else if (
          new Date(todayYear, todayMonth + 1, futureDay).getDay() === 4
        ) {
          addToCalendar.push({
            day: "Jueves",
            date: `${futureDay}/${todayMonth + 2}`,
            fullDate: new Date(
              todayMonth + 2 <= 12 ? todayYear : todayYear + 1,
              todayMonth + 1,
              futureDay
            ),
          });
        } else if (
          new Date(todayYear, todayMonth + 1, futureDay).getDay() === 5
        ) {
          addToCalendar.push({
            day: "Viernes",
            date: `${futureDay}/${todayMonth + 2}`,
            fullDate: new Date(
              todayMonth + 2 <= 12 ? todayYear : todayYear + 1,
              todayMonth + 1,
              futureDay
            ),
          });
        }
      }
    }
    setCalendar(addToCalendar);
    setloading(false);
  }, []);

  const manageDate = (e) => {
    setDate({ ...date, [e.target.name]: e.target.value });
  };

  return (
    !loading && (
      <section className="datepicker">
        <select
          className="day"
          name="day"
          value={date.day}
          onChange={(e) => manageDate(e)}
        >
          <option value="" disabled>
            Fecha
          </option>
          {calendar.map((d, k) => {
            return (
              <option key={k} value={d.fullDate}>
                {d.day} {d.date}
              </option>
            );
          })}
        </select>
        <select
          className="time"
          name="time"
          value={date.time}
          disabled={!date.day}
          onChange={(e) => manageDate(e)}
        >
          <option value="" disabled>
            Hora
          </option>
          {schedual[date.day.split(" ")[0] === "Fri" ? 1 : 0].map((s, k) => {
            return (
              <option key={k} value={s}>
                {s}
              </option>
            );
          })}
        </select>
      </section>
    )
  );
}

export default DatePicker;
